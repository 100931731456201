import { last } from "lodash";
import titleCase from "@/utils/titleCase";

/**
 * @typedef {Object} RawAct An entry in the script JSON for an act
 */

/**
 * @typedef {Object} RawScene An entry in the script JSON for a scene
 */

/**
 * @typedef {Object} RawLine
 */

export function createAct(act, actNumber) {
  return {
    type: "act",
    name: act.text,
    actNumber: actNumber,
    scenes: []
  };
}

export function addSceneToAct(scene, act) {
  act.scenes.push(createScene(scene));
}

export function createScene(scene, actNumber, sceneNumber) {
  return {
    type: "scene",
    id: scene.wdId,
    sceneNumber: sceneNumber,
    description: scene.text,
    parentAct: actNumber,
    lines: []
  };
}

function addLineToScene(line, scene) {
  scene.lines.push(line);
}

export function addDialogToScene(
  dialog,
  actNumber,
  sceneNumber,
  lineNumber,
  scene
) {
  addLineToScene(
    createDialog(dialog, actNumber, sceneNumber, lineNumber),
    scene
  );
}

export function addParentheticalToScene(parenthetical, scene) {
  const dialogLines = scene.lines.filter(line => line.type === "dialog");
  const lastLine = last(dialogLines);
  addParentheticalToLine(parenthetical, lastLine);
}

export function addActionToScene(action, scene) {
  addLineToScene(createAction(action), scene);
}

export function createDialog(dialog, actNumber, sceneNumber, lineNumber) {
  return {
    type: "dialog",
    speaker: titleCase(dialog.charactersSpeaking[0]),
    text: dialog.text,
    parenthetical: dialog.parenthetical ? dialog.parenthetical : false,
    parentId: dialog.sceneWdId,
    parentAct: actNumber,
    parentScene: sceneNumber,
    lineName: `D-Scene-${actNumber
      .toString()
      .padStart(2, 0)}-${sceneNumber
      .toString()
      .padStart(2, 0)}-${lineNumber.toString().padStart(3, 0)}-${titleCase(
      dialog.charactersSpeaking[0]
    )}`,
    lineNumber: lineNumber
  };
}

export function addParentheticalToLine(parenthetical, line) {
  line.text = `<span class="dialog__parenthetical">${parenthetical.text}</span>${line.text}`;
}

export function createAction(action) {
  return {
    type: "action",
    description: action.text,
    parentId: action.sceneWdId
  };
}
