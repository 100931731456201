import { SET_ACTS } from "@/utils/StoreConstants";
import { processJSON } from "@/utils/DataTransformers";

export const state = () => ({
  acts: []
});

export const mutations = {
  setActs(state, acts) {
    state.acts = acts;
  },
  addAct(state, act) {
    state.acts.push(act);
  }
};

export const actions = {
  async loadScript() {
    console.log("loading script");
    const json = await this.$axios.$get("/data/script.json", {
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    });
    const string = JSON.stringify(json);
    const parsed = JSON.parse(string);
    const script = processJSON(parsed);
    this.commit(SET_ACTS, script);
  }
};
