<template>
  <div class="default-layout">
    <nuxt />
  </div>
</template>

<script>
export default {};
</script>

<style>
.default-layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 960px;
  padding: 0 1rem;
}
</style>
