import { cloneDeep, find, last } from "lodash";
import { NEW_ACT, SCENE, DIALOG, ACTION, PARENS } from "./DataConstants";
import {
  createAct,
  createScene,
  addDialogToScene,
  addParentheticalToScene,
  addActionToScene
} from "./ScriptElements";

export function getActs({ lines }) {
  const cloneData = cloneDeep(lines);
  const acts = cloneData.filter(entry => entry.type === NEW_ACT);
  return acts;
}

/**
 * Get all the acts in a scene
 * @param {Act} act
 * @returns {Scene[]}
 */
export function getScenesInAct(act) {
  return act.scenes;
}

export function findSceneById(id, act) {
  const scenes = getScenesInAct(act);
  return find(scenes, ["id", id]);
}

/**
 *
 * @param {Line} line
 * @param {Act} act
 */
export function findLineParentInAct(line, act) {
  const { sceneWdId: parentId } = line;
  return findSceneById(parentId, act);
}

/**
 *
 * @param {Line} line
 * @param {Act} act
 */
export function addDialogToAct(line, actNumber, sceneNumber, lineNumber, act) {
  const parentScene = findLineParentInAct(line, act);
  addDialogToScene(line, actNumber, sceneNumber, lineNumber, parentScene);
}

/**
 *
 * @param {Object} paren
 * @param {Act} act
 */
export function addParenToAct(paren, act) {
  const parentSceneId = paren.sceneWdId;
  const parentScene = findSceneById(parentSceneId, act);
  addParentheticalToScene(paren, parentScene);
}

export function addActionToAct(action, act) {
  const parentScene = findLineParentInAct(action, act);
  addActionToScene(action, parentScene);
}

/**
 *
 * @param {Object} json
 * @returns {Act[]}
 */
export function processJSON(json) {
  json = cloneDeep(json);
  // debugger;
  let actNumber = 0;
  let sceneNumber = 0;
  let lineNumber = 0;
  const acts = [];
  let parenthetical = null;
  json.lines.forEach(line => {
    switch (line.type) {
      case NEW_ACT:
        lineNumber = 0;
        sceneNumber = 0;
        actNumber += 1;
        acts.push(createAct(line, actNumber));
        break;
      case SCENE:
        lineNumber = 0;
        sceneNumber += 1;
        last(acts).scenes.push(createScene(line, actNumber, sceneNumber));
        break;
      case DIALOG:
        lineNumber += 1;
        if (parenthetical) {
          // debugger;
          line.parenthetical = parenthetical;
          parenthetical = null;
        }
        addDialogToAct(line, actNumber, sceneNumber, lineNumber, last(acts));
        break;
      case ACTION:
        addActionToAct(line, last(acts));
        break;
      case PARENS:
        // debugger;
        parenthetical = line.text;
        break;
      default:
        break;
    }
  });
  return acts;
}
