const StoreConstants = {}

export const SET_ACTS = "script/setActs";
StoreConstants.SET_ACTS = SET_ACTS;

export const LOAD_SCRIPT = "script/loadScript";
StoreConstants.LOAD_SCRIPT = LOAD_SCRIPT;

export const ADD_ACT = "script/addAct";

export default StoreConstants;