import Vue from 'vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../assets/scss/_global.scss'

import _6f6c098b from '../layouts/default.vue'
import _b47ae5fc from '../layouts/frontpage.vue'

const layouts = { "_default": _6f6c098b,"_frontpage": _b47ae5fc }

export default {
  head: {"title":"Maporino Script","script":[{"src":"https:\u002F\u002Fpolyfill.io\u002Fv3\u002Fpolyfill.min.js?features=fetch%2CObject.assign%2CObject.entries"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Maporino!"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"msapplication-config","content":"\u002Fimages\u002Ffavicon\u002Fbrowserconfig.xml"},{"name":"theme-color","content":"#ffffff"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"maporino-script"},{"hid":"author","name":"author","content":"Adrian Bettridge-Wiese"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:title","name":"og:title","property":"og:title","content":"maporino-script"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"maporino-script"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Maporino!"}],"link":[{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Fimages\u002Ffavicon\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Fimages\u002Ffavicon\u002Ffavicon-16x16.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fimages\u002Ffavicon\u002Fapple-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fimages\u002Ffavicon\u002Fapple-touch-icon.png"},{"rel":"manifest","href":"\u002Fimages\u002Ffavicon\u002Fsite.webmanifest"},{"rel":"mask-icon","href":"\u002Fimages\u002Ffavicon\u002Fsafari-pinned-tab.svg","color":"#5bbad5"},{"rel":"shortcut icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_64.9mld2VBMsQ$.png"}],"style":[],"htmlAttrs":{"lang":"en"}},

  render(h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })
    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter(el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [loadingEl, transitionEl])
  },
  data: () => ({
    isOnline: true,
    layout: null,
    layoutName: ''
  }),
  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created() {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this
      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
  },

  mounted() {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline() {
      return !this.isOnline
    }
  },
  methods: {
    refreshOnlineStatus() {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    errorChanged() {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) this.$loading.fail()
        if (this.$loading.finish) this.$loading.finish()
      }
    },

    setLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
  components: {
    NuxtLoading
  }
}
